import Vue from 'vue'
// import store from '../store'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    component: () => import('../OuterView.vue'),
    children:[
      {path: '/home', component: () => import('../views/Home.vue')},
      {path: '/summary', component: () => import('../views/Summary.vue')},
      {path: '/solar', component: () => import('../views/Solar.vue')},
      {path: '/generator', component: () => import('../views/Generator.vue')},
      {path: '/grid', component: () => import('../views/Grid.vue')},
      {path: '/battery', component: () => import('../views/Battery.vue')},
      {path: '/settings', component: () => import('../views/Settings.vue')}
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.name != 'Login' && !store.state.userIdentifier) {
  //   next('')
  // } else {
    next()
  // }
})

export default router
