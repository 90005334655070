import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

// var baseUrl = 'https://localhost:44314/';
// var baseUrl = 'http://esapi.cware.co.za/';

export default new Vuex.Store({
  state: {
    baseUrl: '',
    userIdentifier: null,
    selectedLocation: 
    {untName: null,
    untId: null},
    locations: [],
    dashboardInfo: {
      batteryPercentage: 0,
      wattsGenerated: 0, 
      wattsUsed: 0,
      gridInfo: {
        total: 0,
        live: 0
      },
      generatorInfo:{
        total: 0,
        live: 0
      },
      solarInfo: {
        total: 0,
        live: 0
      }
    },
    currentScreen: 'home',


    loginUserRequest: null,
    summaryRequest: null,
    saveLocationSaveRequest: null,
    getLocationInformationRequest: null,
    addLocationRequest: null,
    getSummaryDataRequest: null,
    removeDeviceRequest: null,
    updateDeviceRequest: null
  },
  mutations: {
    setApiUrl: (state, payload) => {state.baseUrl = payload},
    // changeLocation: (state, payload) => {state.selectedLocation = payload},
     
    changeLocation: (state, payload) => {
      state.selectedLocation = payload
      localStorage.setItem('selectedLocationId', payload.untId)
      localStorage.setItem('selectedLocationName', payload.untName)
    },
    changeScreen: (state, payload) => {state.currentScreen = payload},
    clearUserInfo: (state) => {
      state.userIdentifier = null
      localStorage.removeItem('userIdentifier')
    },
    setLocationsAfterReload: (state, payload) => { state.locations = payload },

    setGetLoginUserRequest: (state, payload) => { state.loginUserRequest = payload },
    setGetSummaryRequest: (state, payload) => { state.summaryRequest = payload },
    setGetLocationInformationRequest: (state, payload) => {state.getLocationInformationRequest = payload},
    setSaveLocationRequest: (state, payload) => {state.saveLocationSaveRequest = payload},
    setAddLocationRequest: (state, payload) => {state.addLocationRequest = payload},
    setGetSummaryDataRequest: (state, payload) => {state.getSummaryDataRequest = payload},
    setRemoveDeviceRequest: (state, payload) => {state.removeDeviceRequest = payload},
    setUpdateDeviceRequest: (state, payload) => {state.updateDeviceRequest = payload}
  },
  actions: {
    loginUser({state}){
      
      let payload = state.loginUserRequest
      return new Promise((resolve, reject) =>{
        axios.post(state.baseUrl + 'Token/auth', payload)
        .then(response => {
          let lastUser = localStorage.getItem('lastUser')
          if (payload.email !== lastUser) {
            localStorage.removeItem('selectedLocationId')
            localStorage.removeItem('selectedLocationName')
          }
          localStorage.setItem('lastUser', payload.email)
          state.userIdentifier = response.data
          localStorage.setItem('userIdentifier', response.data)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    getDashboardInfo({state}){
      
      if (localStorage.getItem('selectedLocationId') == null) {
        return {data : null}
      }
      
      state.dashboardInfo = {
        batteryPercentage: 0,
        wattsGenerated: 0, 
        wattsUsed: 0,
        gridInfo: {
          total: 0,
          live: 0
        },
        generatorInfo:{
          total: 0,
          live: 0
        },
        solarInfo: {
          total: 0,
          live: 0
        }
      }
      let urlDestination = state.baseUrl + 'EventData/gettodayssummary/' + localStorage.getItem('selectedLocationId')
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('userIdentifier')
        }
      }

      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    getLoggedInLocations({state}){
      state.locations = []
      let urlDestination = state.baseUrl + 'UserUnit/get'
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('userIdentifier')
        }
      }

      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          state.locations = response.data
          localStorage.setItem('locations', JSON.stringify(response.data))
          resolve()
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    
    getAllSummarries({state}){
      if (localStorage.getItem('selectedLocationId') == null) {
        return {data : null}
      }
      let urlDestination = state.baseUrl + 'EventData/GetDaylyReadings/' + localStorage.getItem('selectedLocationId') + '/' + state.summaryRequest.category + '/' + state.summaryRequest.fromDate + '/' + state.summaryRequest.toDate
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('userIdentifier')
        }
      }

      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    getDateSummaryForSolar({state}){
      
      if (localStorage.getItem('selectedLocationId') == null) {
        return {data : null}
      }
      let urlDestination = state.baseUrl + 'EventData/GetHourlylyReadings/' + localStorage.getItem('selectedLocationId') + '/ES_Solar/' + state.getSummaryDataRequest.selectedDate
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('userIdentifier')
        }
      }
      
      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    getDateSummaryForGenerator({state}){
      if (localStorage.getItem('selectedLocationId') == null) {
        return {data : null}
      }
      let urlDestination = state.baseUrl + 'EventData/GetHourlylyReadings/' + localStorage.getItem('selectedLocationId') + '/ES_Generator/' + state.getSummaryDataRequest.selectedDate
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('userIdentifier')
        }
      }
      
      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    getDateSummaryForGrid({state}){
      if (localStorage.getItem('selectedLocationId') == null) {
        return {data : null}
      }
      let urlDestination = state.baseUrl + 'EventData/GetHourlylyReadings/' + localStorage.getItem('selectedLocationId') + '/ES_Grid/' + state.getSummaryDataRequest.selectedDate
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('userIdentifier')
        }
      }
      
      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    
    getBatterySummary({state}){
      if (localStorage.getItem('selectedLocationId') == null) {
        return {data : null}
      }
      let urlDestination = state.baseUrl + 'Unit/BateryInfo/' + localStorage.getItem('selectedLocationId') 
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('userIdentifier')
        }
      }
      
      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    updateDevice({state}){
      
      let payload = {
        "batteryMinVolts" : state.updateDeviceRequest.untProperties.batteryMinVolts,
        "batteryMaxVolts" : state.updateDeviceRequest.untProperties.batteryMaxVolts,
        "generatorReferenceVolts" : state.updateDeviceRequest.untProperties.generatorReferenceVolts,
        "solarReferenceVolts" : state.updateDeviceRequest.untProperties.solarReferenceVolts,
        "gridReferenceVolts" : state.updateDeviceRequest.untProperties.gridReferenceVolts
      }
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'UserUnit/linkupdateunit/' + state.updateDeviceRequest.identifier + '/' + state.updateDeviceRequest.description,
        headers: { 
          'Authorization': 'Bearer '+ localStorage.getItem('userIdentifier'), 
          'Content-Type': 'application/json'
        },
        data : payload
      };
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
      
    },

    removeDevice({state}){
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'UserUnit/remove/' + state.removeDeviceRequest.id,
        headers: { 
          'Authorization': 'Bearer '+ localStorage.getItem('userIdentifier')
        }
      }
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })

    }
  },
  modules: {
  }
})
